<template>
  <a-spin :spinning="loading">
    <a-empty
      v-if="exam_list.length == 0"
      description="暂无考试"
      style="padding-top: 50px"
    ></a-empty>
    <div class="exam-box">
      <div
        class="exam-box-e flex flex-between"
        v-for="(item, i) in exam_list"
        :key="i"
        :class="(i + 1) % 2 == 0 ? '' : 'mr-60'"
        @click="goLink(item.exam_id)"
      >
        <div>
          <div class="name font-18 font-bold flex mb-10">
            {{ item.exam_name }}
            <div v-if="item.exam_haspassed == 1" class="tag">已通过</div>
          </div>
          <div class="score font-16 text-gray">
            <span style="margin-right: 10px">总分：{{ item.exam_score }}</span>
            <span>通过：{{ item.exam_pass_score }}</span>
          </div>
          <div class="font-16 text-gray">
            {{ item.exam_start_time + " - " + item.exam_end_time }}
          </div>
        </div>
        <img class="arrow" src="@/assets/right-arrow.png" />
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-bottom: 80px">
      <a-pagination
        v-model:current="page.page"
        v-model:pageSize="page.limit"
        show-size-changer
        :total="count"
        :hideOnSinglePage="true"
        @change="onShowSizeChange"
        :pageSizeOptions="['15', '30', '50', '100']"
      ></a-pagination>
    </div>
  </a-spin>
</template>
<script setup>
import { getUserExamList } from "@/api/main.js";
import { useStore } from "vuex";
import { ref } from "vue";
import { encode } from "js-base64";
import url from "@/utils/url.js";
const store = useStore();
const loading = ref(false);
const exam_list = ref([]);
const page = ref({
  page: 1,
  limit: 15
});
const count = ref(0);
// console.log(store);
const getExamList = async () => {
  loading.value = true;
  const res = await getUserExamList({
    user_id: store.state.user.user_id,
    ...page.value
  });
  count.value = res.count;
  // console.log(res);
  loading.value = false;
  exam_list.value = res.data.exam;
};
getExamList();
const goLink = exam_id => {
  url.navigateTo("/exam/examdata", { t: encode(`${exam_id}`) });
};
const onShowSizeChange = (page, limit) => {
  getExamList();
};
</script>
<style lang="less" scoped>
.exam-box {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  margin: 23px auto 80px auto;
  position: relative;
  .mr-60 {
    margin-right: 60px;
  }
  .exam-box-e {
    cursor: pointer;
    width: 570px;
    height: 136px;
    position: relative;
    border-bottom: 1px solid #dfdfdf;
    .name {
      position: relative;
      .tag {
        left: 0;
        text-align: center;
        line-height: 23px;
        font-size: 14px;
        color: #fff;
        width: 81px;
        height: 23px;
        background-color: #1276cb;
        border-radius: 11px;
        margin-left: 11px;
      }
    }
    .score {
      width: 165px;
      line-height: 1;
    }
    .arrow {
      right: 0;
      top: 50%;
      width: 9px;
      height: auto;
      transform: translateY(-50%);
    }
  }
}
</style>
